/**
 * Appends a video element from the origin container to the destiny container.
 *
 * @param {string|HTMLElement} origin - The origin container ID or element.
 * @param {string} destiny - The destiny container ID.
 */
const applyAppendVideo = (origin, destiny) => {
  const originContainer = typeof origin === 'string' ? document.getElementById(origin) : origin;
  const destinyContainer = document.getElementById(destiny);

  destinyContainer?.appendChild(originContainer);
};

/**
 * Sets the video append for the given origin and destiny elements.
 * @param {string|HTMLElement} origin - The origin element to append the video from.
 * @param {string} destiny - The destiny container ID.
 * @param {number} [time=0] - The time in milliseconds to wait before applying the append.
 */
const setVideoAppend = (origin, destiny, time = 0) => {
  if (!origin || !destiny) {
    return;
  }

  if (time) {
    setTimeout(() => {
      applyAppendVideo(origin, destiny);
    }, time);
  } else {
    applyAppendVideo(origin, destiny);
  }
};

module.exports = { setVideoAppend };
