const { CLIPS_PROVIDERS } = require('../constants');
const VideoJS = require('./VideoJS');

const providers = {};

providers[CLIPS_PROVIDERS.VIDEOJS] = {
  component: VideoJS,
  play: player => player?.play(),
  pause: player => player?.pause(),
  mute: player => player?.muted(true),
  unmute: player => player?.muted(false),
  suscribe: (player, event, action) => {
    player.on(event, action);
  },
  unsuscribe: (player, event) => {
    player.off(event);
  },
  src: (player, source) => player?.src(source),
  reset: (player, callback) => {
    if (player?.readyState() !== VideoJS.READY_STATE.HAVE_NOTHING) {
      player?.pause();

      player?.currentTime(0);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      callback && callback();
    }
  },
  restart: player => {
    player?.currentTime(0);

    if (player?.paused()) {
      player.play().catch(() => {});
    }
  },
  removeRestrictions: player =>
    new Promise((resolve, reject) => {
      if (!player) {
        reject();
      }

      if (player?.autoplay()) {
        player?.muted(false);
        player?.muted(true);
        resolve();
      } else {
        player?.play()?.then(() => {
          player?.pause();
          player?.currentTime(0);
          resolve();
        });
      }
    }),
  clickVideo: player => {
    if (player?.paused()) {
      return player.play();
    }

    player?.pause();
    return Promise.resolve();
  },
  tapVideo: player => {
    if (player?.autoplay() && player?.muted()) {
      player?.muted(false);
    } else if (player?.paused()) {
      return player?.play();
    } else {
      player?.pause();
    }

    return Promise.resolve();
  },
  isPlaying: player => !player?.paused(),
  isMuted: player => player?.muted(),
};

module.exports = providers;
