const React = require('react');

const { useState, useEffect, useRef, useImperativeHandle } = React;
const { arrayOf, bool, func, shape, string } = require('prop-types');
const throttle = require('lodash/throttle');

const { GALLERY_MODE, ID_CONTAINER } = require('../../constants');
const { DEVICE_TYPE } = require('../../../../utils/constants');
const { isItemClip } = require('../../hooks/use-gallery');
const { trackPage, trackEvent } = require('../../../../lib/tracking');
const { CLIP_VIDEO_ID, CLIPS_VIDEO_PROVIDER, SNACKBARS_ID } = require('../../../clip-video/constants');
const {
  isMuted,
  isPlaying,
  mute,
  pause,
  play,
  removeRestrictions,
  reset,
  restart,
  tapVideo,
  unmute,
} = require('../../../clip-video/Provider');
const { updateMobileMediaElements } = require('../../../clip-multimedia/utils/multimedia-events');
const ClipVideo = require('../../../clip-video');
const { setVideoAppend } = require('../../../clip-video/utils/video-append');
const { PROVIDER, MULTIMEDIA_PROVIDERS } = require('../../../figure/constants');

const getMultimediaProps = (videoId, multimedia, isFullscreen) => ({
  multimedia,
  videoContainer: {
    current: isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
    opposite: !isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN,
  },
  videoId,
});

// ignore coverage for internal videojs lib events
const getClipMethodsForMobile = ({
  multimediaClipRef,
  playerRef,
  clipsConfig,
  isCurrentClips,
  isFullscreen,
  spinnerConfig,
  isFullscreenRef,
  errorConfig,
  showSnackbar,
  namespace,
  deviceType,
}) => {
  const handleTouch = (index, { className }) => {
    if (className.includes('glass-screen') && !multimediaClipRef.current.loading) {
      tapVideo(playerRef.current, CLIPS_VIDEO_PROVIDER);
    }
  };

  const onSrcChanged = (player, isFirstRender) => {
    if (clipsConfig?.autoplay) {
      if (!isCurrentClips && !isFirstRender) {
        reset(player, CLIPS_VIDEO_PROVIDER);
      }
    } else if (isFullscreen) {
      play(player, CLIPS_VIDEO_PROVIDER);
    }
  };

  const onWaiting = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.loading = true;

    spinnerConfig.current.spinnerDelayId = setTimeout(() => {
      const multimediaProps = getMultimediaProps(currentVideo?.id, multimediaClipRef.current, isFullscreenRef.current);
      updateMobileMediaElements(multimediaProps);
    }, spinnerConfig.current.spinnerDelay);

    if (multimediaClipRef.current.error) {
      const { message, delay } =
        clipsConfig?.snackbars?.find(snackbar => snackbar.id === SNACKBARS_ID?.OWN_ERROR) || {};

      if (message) {
        errorConfig.current.delayId = setTimeout(() => {
          showSnackbar({ message, type: 'generic', delay, called_from: `${namespace}-${deviceType}` });
        }, errorConfig.current.delay);
      }
    }
  };

  const onPlay = () => {
    multimediaClipRef.current.paused = false;
  };

  const onPlaying = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.hasStarted = true;
    multimediaClipRef.current.loading = false;
    multimediaClipRef.current.paused = false;

    const multimediaProps = getMultimediaProps(currentVideo?.id, multimediaClipRef.current, isFullscreenRef.current);
    updateMobileMediaElements(multimediaProps);

    clearTimeout(spinnerConfig.current.spinnerDelayId);
  };

  const onPause = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.paused = true;
    multimediaClipRef.current.loading = false;

    const multimediaProps = getMultimediaProps(currentVideo?.id, multimediaClipRef.current, isFullscreenRef.current);
    updateMobileMediaElements(multimediaProps);

    clearTimeout(spinnerConfig.current.spinnerDelayId);
  };

  const onVolumeChange = (player, options) => {
    const { currentVideo } = options;

    multimediaClipRef.current.muted = isMuted(player, CLIPS_VIDEO_PROVIDER);

    const multimediaProps = getMultimediaProps(currentVideo?.id, multimediaClipRef.current, isFullscreenRef.current);
    updateMobileMediaElements(multimediaProps);
  };

  const onTimeUpdate = throttle(player => {
    if (player.currentTime() > 0) {
      multimediaClipRef.current.timeToLeave += 1;
    }
  }, 1000);

  const onError = player => {
    multimediaClipRef.current.error = true;
    player?.error(null);
  };

  return {
    handleTouch,
    onSrcChanged,
    onWaiting,
    onPlay,
    onPlaying,
    onPause,
    onVolumeChange,
    onTimeUpdate,
    onError,
  };
};

const useClipsEffects = ({
  clipsConfig,
  videoClipElRef,
  multimediaClipRef,
  isFullscreenRef,
  playerRef,
  snap,
  isCurrentClips,
  isFullscreen,
  isPreviousClips,
  onPause,
  position,
  multimediaWatched,
  setMultimediaWatched,
}) => {
  useEffect(() => {
    if (clipsConfig?.hasClips) {
      videoClipElRef.current = document.getElementById(CLIP_VIDEO_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const { current, previous, prevClip } = snap;
    const videoStateFormated = {
      is_muted:
        typeof multimediaClipRef.current.muted === 'undefined' ? 'not_started' : `${multimediaClipRef.current.muted}`,
      is_playing:
        typeof multimediaClipRef.current.paused === 'undefined'
          ? 'not_started'
          : `${!multimediaClipRef.current.paused}`,
    };

    if (isCurrentClips) {
      multimediaClipRef.current.hasStarted = false;
      multimediaClipRef.current.error = false;
      multimediaClipRef.current.loading = false;
      multimediaClipRef.current.paused = true;
      multimediaClipRef.current.timeToLeave = 0;

      if (clipsConfig?.autoplay) {
        if (!isItemClip(previous)) {
          restart(playerRef.current, CLIPS_VIDEO_PROVIDER);
        }

        if (isFullscreen && multimediaClipRef.current.muted) {
          unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
        }
      } else if (!isItemClip(previous) && isFullscreen) {
        play(playerRef.current, CLIPS_VIDEO_PROVIDER);
      }

      const clipContainer = isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN;
      setVideoAppend(videoClipElRef.current, `${clipContainer}-${current?.id}`);

      let trackToSend = current?.track_vip || current?.track_pdp;
      trackToSend = {
        ...trackToSend,
        melidata_event: {
          ...trackToSend?.melidata_event,
          event_data: {
            ...trackToSend?.melidata_event.event_data,
            gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
          },
        },
      };

      trackPage(trackToSend);
    }

    if (isPreviousClips) {
      let trackToSend = previous?.track_leave;
      const multimediaDataTrack = {
        time_to_leave: multimediaClipRef.current.timeToLeave,
        has_started_at_least_once: multimediaClipRef.current.hasStarted,
        ...videoStateFormated,
      };

      trackToSend = {
        ...trackToSend,
        melidata_event: {
          ...trackToSend?.melidata_event,
          event_data: {
            ...trackToSend?.melidata_event.event_data,
            ...multimediaDataTrack,
            gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
          },
        },
      };

      trackEvent(trackToSend);

      // The events are not being triggered when withEvents=false in <ClipsVideo />
      reset(playerRef.current, CLIPS_VIDEO_PROVIDER, () => {
        multimediaClipRef.current.hasStarted = false;
        onPause(playerRef.current, { currentVideo: prevClip }); // Execute just the logic of the event
      });

      if (isFullscreen) {
        setVideoAppend(videoClipElRef.current, `${ID_CONTAINER.GALLERY_SCREEN}-${previous?.id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.current, position.currentClip]);

  useEffect(() => {
    isFullscreenRef.current = isFullscreen;
    const { current } = snap;
    const clipContainer = isFullscreen ? ID_CONTAINER.FULL_SCREEN : ID_CONTAINER.GALLERY_SCREEN;

    if (isCurrentClips) {
      setVideoAppend(videoClipElRef.current, `${clipContainer}-${current?.id}`);

      const paused = !isPlaying(playerRef.current, CLIPS_VIDEO_PROVIDER);

      if (clipsConfig?.autoplay) {
        if (isFullscreen) {
          unmute(playerRef.current, CLIPS_VIDEO_PROVIDER);
        } else {
          mute(playerRef.current, CLIPS_VIDEO_PROVIDER);

          if (paused) {
            play(playerRef.current, CLIPS_VIDEO_PROVIDER);
          }
        }
      } else if (!isFullscreen && !paused) {
        pause(playerRef.current, CLIPS_VIDEO_PROVIDER);
      } else if (isFullscreen) {
        play(playerRef.current, CLIPS_VIDEO_PROVIDER);
      }
    }

    if (isFullscreen && isCurrentClips && !multimediaWatched[position.current]) {
      const trackToSend = {
        ...current?.track_intention_to_view,
        melidata_event: {
          ...current?.track_intention_to_view.melidata_event,
          event_data: {
            ...current?.track_intention_to_view.melidata_event.event_data,
            gallery_mode: isFullscreen ? GALLERY_MODE.FULL_SCREEN : GALLERY_MODE.DEFAULT,
          },
        },
      };

      trackEvent(trackToSend);
      setMultimediaWatched(watchedList => {
        watchedList[position.current] = true;

        return watchedList;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen]);
};

const getAsyncTapHandler = ({ onceRef, playerRef, multimediaClipRef, snap, onPause }) => itemProvider =>
  new Promise(resolve => {
    if (!onceRef.current) {
      resolve();
    }

    if (itemProvider === PROVIDER.CLIP) {
      onceRef.current = false;
      resolve();
    }

    if ([...MULTIMEDIA_PROVIDERS, PROVIDER.IMAGE].includes(itemProvider)) {
      removeRestrictions(playerRef.current, CLIPS_VIDEO_PROVIDER)
        .then(() => {
          multimediaClipRef.current.hasStarted = false; // To force the clip's thumbnail to be shown
          onPause(playerRef.current, { currentVideo: snap.currentClip }); // Execute just the logic of the event
        })
        .finally(() => {
          onceRef.current = false;
          resolve();
        });
    }
  });

const ClipsWorkerComponent = React.forwardRef(
  (
    {
      clipsConfig,
      isCurrentClips,
      isPreviousClips,
      isFullscreen,
      showSnackbar,
      namespace,
      deviceType,
      snap,
      position,
      figures,
    },
    ref,
  ) => {
    const [multimediaWatched, setMultimediaWatched] = useState(() => figures.map(() => false));
    const playerRef = useRef(null);
    const onceRef = useRef(true);
    const videoClipElRef = useRef(null);
    const isFullscreenRef = useRef();
    const spinnerConfig = useRef({
      spinnerDelay: 1000,
      spinnerDelayId: undefined,
    });

    const errorConfig = useRef({
      delay: 3000,
      delayId: undefined,
    });

    const multimediaClipRef = useRef({
      error: false,
      hasStarted: false,
      loading: undefined,
      paused: true,
      muted: clipsConfig?.autoplay,
      timeToLeave: 0,
    });

    const {
      handleTouch,
      onSrcChanged,
      onWaiting,
      onPlay,
      onPlaying,
      onPause,
      onVolumeChange,
      onTimeUpdate,
      onError,
    } = getClipMethodsForMobile({
      multimediaClipRef,
      playerRef,
      clipsConfig,
      isCurrentClips,
      isFullscreen,
      spinnerConfig,
      isFullscreenRef,
      errorConfig,
      showSnackbar,
      namespace,
      deviceType,
    });

    useClipsEffects({
      clipsConfig,
      videoClipElRef,
      multimediaClipRef,
      isFullscreenRef,
      playerRef,
      snap,
      isCurrentClips,
      isFullscreen,
      onPause,
      isPreviousClips,
      position,
      multimediaWatched,
      setMultimediaWatched,
    });

    const clipHandlerAsync = getAsyncTapHandler({ onceRef, clipsConfig, playerRef, multimediaClipRef, snap, onPause });

    useImperativeHandle(
      ref,
      () => ({
        clipHandlerAsync,
        handleTouch,
      }),
      [clipHandlerAsync, handleTouch],
    );

    return (
      <ClipVideo
        ref={playerRef}
        id={CLIP_VIDEO_ID}
        currentVideo={snap.currentClip}
        autoplay={clipsConfig?.autoplay}
        deviceType={DEVICE_TYPE.MOBILE}
        onError={onError}
        onPause={onPause}
        onPlay={onPlay}
        onPlaying={onPlaying}
        onSrcChanged={onSrcChanged}
        onTimeUpdate={onTimeUpdate}
        onVolumeChange={onVolumeChange}
        onWaiting={onWaiting}
        withEvents={isCurrentClips}
        hide
      />
    );
  },
);

ClipsWorkerComponent.propTypes = {
  clipsConfig: shape({}),
  isCurrentClips: bool,
  isPreviousClips: bool,
  isFullscreen: bool,
  showSnackbar: func,
  namespace: string,
  deviceType: string,
  snap: shape({}),
  position: shape({}),
  figures: arrayOf(shape({})),
};

module.exports = {
  ClipsWorkerComponent,
  getAsyncTapHandler,
  useClipsEffects,
  getClipMethodsForMobile,
};
