/* eslint-disable no-return-assign */

const React = require('react');

const classnames = require('classnames');
const { bool, func, shape, string } = require('prop-types');
const { CLIPS_EVENTS, CLIPS_VIDEO_PROVIDER } = require('./constants');
const { DEVICE_TYPE } = require('../../utils/constants');
const { getVideoProvider, setSrc } = require('./Provider');

const { forwardRef, useEffect, useMemo, useRef, useState } = React;

const namespace = 'clip-video-container';
const clipPlayerClassName = 'clip-player';
const clipPlayerContainerClassName = `${clipPlayerClassName}-container`;

const videoOptionsDefault = {
  autoplay: false,
  controls: false,
  loadingSpinner: false,
  loop: false,
  muted: true,
  playsinline: true,
  preload: 'auto',

  html5: {
    hls: {
      enableLowInitialPlaylist: true,
      smoothQualityChange: true,
      overrideNative: true,
    },
  },
};

const VideoProvider = getVideoProvider(CLIPS_VIDEO_PROVIDER);

const ClipVideo = forwardRef((props, playerRef) => {
  const {
    autoplay,
    currentVideo,
    deviceType,
    hide,
    id,
    onError = () => {},
    onPause = () => {},
    onPlay = () => {},
    onPlaying = () => {},
    onSrcChanged = () => {},
    onTimeUpdate = () => {},
    onVolumeChange = () => {},
    onWaiting = () => {},
    preloadId,
    preloadVideo,
    withEvents,
  } = props;

  const [currentOptions, setCurrentOptions] = useState({});
  const [preloadOptions, setPreloadOptions] = useState({});
  const preloadPlayerRef = useRef(null);

  const hasCurrentSources = Boolean(currentVideo?.video_url && currentVideo?.video_type);
  const hasPreloadSources = Boolean(preloadVideo?.video_url && preloadVideo?.video_type);

  useEffect(() => {
    if (hasCurrentSources) {
      const newOptions = {
        ...videoOptionsDefault,
        autoplay,
        className: clipPlayerClassName,
        currentVideo,
        dataTestid: `${clipPlayerClassName}-current`,
        loop: true,
        muted: autoplay,
      };

      setCurrentOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, currentVideo]);

  useEffect(() => {
    /* istanbul ignore next */
    if (hasPreloadSources) {
      const newOptions = {
        ...videoOptionsDefault,
        autoplay,
        className: clipPlayerClassName,
        dataTestid: `${clipPlayerClassName}-preload`,
        preloadVideo,
      };

      setPreloadOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, preloadVideo]);

  // ignore coverage for internal videojs lib events
  /* istanbul ignore next */
  const eventsVideo = useMemo(
    () => ({
      [CLIPS_EVENTS.ERROR.eventName]: (...args) => onError(...args),
      [CLIPS_EVENTS.PAUSE.eventName]: (...args) => onPause(...args),
      [CLIPS_EVENTS.PLAY.eventName]: (...args) => onPlay(...args),
      [CLIPS_EVENTS.PLAYING.eventName]: (...args) => onPlaying(...args),
      [CLIPS_EVENTS.TIMEUPDATE.eventName]: (...args) => onTimeUpdate(...args),
      [CLIPS_EVENTS.VOLUMECHANGE.eventName]: (...args) => onVolumeChange(...args),
      [CLIPS_EVENTS.WAITING.eventName]: (...args) => onWaiting(...args),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentOptions],
  );

  const onPlayReady = (player, optionsFromInstance, isFirstRender) => {
    const { currentVideo: video } = optionsFromInstance;
    const sources = [{ src: video?.video_url, type: video?.video_type }];

    setSrc(player, sources, CLIPS_VIDEO_PROVIDER);

    if (typeof onSrcChanged === 'function') {
      onSrcChanged(player, isFirstRender);
    }
  };

  // istanbul ignore next
  const onPreloadPlayReady = (player, { preloadVideo: video }) => {
    const sources = [{ src: video?.video_url, type: video?.video_type }];

    setSrc(player, sources, CLIPS_VIDEO_PROVIDER);
  };

  const mainClases = classnames(
    'video-js',
    clipPlayerContainerClassName,
    `${clipPlayerContainerClassName}--${deviceType}`,
  );
  const namespaceContainer = classnames(namespace, { [`${namespace}--hide`]: hide });

  return (
    <div className={namespaceContainer}>
      <VideoProvider
        ref={playerRef}
        id={id}
        classNameWrapper={mainClases}
        dataTestid={`${clipPlayerClassName}-${currentVideo?.id}`}
        eventsVideo={eventsVideo}
        onPlayReady={onPlayReady}
        options={currentOptions}
        withEvents={withEvents}
      />
      {hasPreloadSources && preloadId && (
        <VideoProvider
          ref={preloadPlayerRef}
          id={preloadId}
          dataTestid={`${clipPlayerClassName}-preload-${preloadVideo?.id}`}
          onPlayReady={onPreloadPlayReady}
          options={preloadOptions}
          delay={300}
        />
      )}
    </div>
  );
});

const eventsTypes = {};
const eventsDefaultTypes = {};
Object.values(CLIPS_EVENTS).forEach(({ functionName }) => (eventsTypes[functionName] = func));
Object.values(CLIPS_EVENTS).forEach(({ functionName }) => (eventsDefaultTypes[functionName] = null));

ClipVideo.propTypes = {
  ...eventsTypes,
  autoplay: bool,
  currentVideo: shape({}),
  deviceType: string,
  hide: bool,
  id: string.require,
  onSrcChanged: func,
  preloadId: string,
  preloadVideo: shape({}),
  withEvents: bool,
};

ClipVideo.defaultProps = {
  ...eventsDefaultTypes,
  autoplay: false,
  currentVideo: null,
  deviceType: DEVICE_TYPE.DESKTOP,
  hide: false,
  onSrcChanged: null,
  preloadId: undefined,
  preloadVideo: null,
  withEvents: true,
};

module.exports = ClipVideo;
