const throttle = require('lodash/throttle');
const { getClipWrapper, toggleClipElement } = require('./toggle-clips-elements');

const initMouseHoverConfig = {
  isHover: false,
  disappearDelay: 1500,
  disappearDelayId: undefined,
  throttleMove: 900,
};

const getDesktopMultimediaTypes = (multimedia, show = true) => {
  let multimediaTypesToShow = [];
  let multimediaTypesToHide = [];

  if (show) {
    multimediaTypesToShow.push('fullscreen', 'exit-fullscreen', 'shadow');

    if (multimedia?.loading) {
      multimediaTypesToShow.push('spinner');
      multimediaTypesToHide.push('play', 'pause');
    } else if (multimedia?.paused) {
      multimediaTypesToShow.push('play');
      multimediaTypesToHide.push('pause', 'spinner');
    } else {
      multimediaTypesToShow.push('pause');
      multimediaTypesToHide.push('play', 'spinner');
    }

    if (multimedia?.hasStarted) {
      multimediaTypesToHide.push('thumbnail');
    } else {
      multimediaTypesToShow.push('thumbnail');
    }

    if (multimedia?.muted) {
      multimediaTypesToShow.push('muted');
      multimediaTypesToHide.push('unmuted');
    } else {
      multimediaTypesToShow.push('unmuted');
      multimediaTypesToHide.push('muted');
    }
  } else {
    multimediaTypesToHide.push('play', 'pause', 'fullscreen', 'exit-fullscreen', 'shadow', 'muted', 'unmuted');

    if (multimedia?.loading) {
      multimediaTypesToShow = ['spinner'];
    } else if (multimedia?.paused) {
      multimediaTypesToShow = ['play', 'fullscreen', 'exit-fullscreen', 'shadow'];
      multimediaTypesToHide = ['pause'];

      if (multimedia.muted) {
        multimediaTypesToShow.push('muted');
        multimediaTypesToHide.push('unmuted');
      } else {
        multimediaTypesToShow.push('unmuted');
        multimediaTypesToHide.push('muted');
      }
    }
  }

  return [multimediaTypesToShow, multimediaTypesToHide];
};

const mobileEvaluateMediaDisplay = multimedia => {
  const multimediaTypesToShow = [];
  const multimediaTypesToHide = [];

  if (multimedia?.loading) {
    multimediaTypesToShow.push('spinner');
    multimediaTypesToHide.push('play', 'big-mute');
  } else if (multimedia?.muted) {
    multimediaTypesToShow.push('big-mute');
    multimediaTypesToHide.push('play', 'spinner');
  } else if (multimedia?.paused) {
    multimediaTypesToShow.push('play');
    multimediaTypesToHide.push('big-mute', 'spinner');
  } else {
    multimediaTypesToHide.push('play');
    multimediaTypesToHide.push('big-mute', 'spinner');
  }

  if (multimedia?.hasStarted) {
    multimediaTypesToHide.push('thumbnail');
  } else {
    multimediaTypesToShow.push('thumbnail');
  }

  return [multimediaTypesToShow, multimediaTypesToHide];
};

const hideDesktopMediaElements = ({ multimedia, videoContainer, videoId }) => {
  const [multimediaTypesToShow, multimediaTypesToHide] = getDesktopMultimediaTypes(multimedia, false);

  toggleClipElement(videoContainer?.current, videoId, multimediaTypesToHide, false);
  toggleClipElement(videoContainer?.current, videoId, multimediaTypesToShow, true);
};

const showDesktopMediaElements = props => {
  const { multimedia, mouseHoverConfig, videoContainer, videoId, isBottomMultimedia = false } = props;
  const [multimediaTypesToShow, multimediaTypesToHide] = getDesktopMultimediaTypes(multimedia);

  toggleClipElement(videoContainer?.current, videoId, multimediaTypesToHide, false);
  toggleClipElement(videoContainer?.current, videoId, multimediaTypesToShow, true);
  toggleClipElement(videoContainer?.opposite, videoId, 'thumbnail', true);

  clearTimeout(mouseHoverConfig?.disappearDelayId);

  if (!isBottomMultimedia) {
    mouseHoverConfig.disappearDelayId = setTimeout(() => {
      hideDesktopMediaElements({ multimedia, videoContainer, videoId });
    }, mouseHoverConfig?.disappearDelay);
  }
};

const handleMouseMove = throttle(
  (props, event) => {
    const { mouseHoverConfig, multimedia, videoContainer, videoId } = props;
    if (mouseHoverConfig.isHover) {
      const { className = '' } = event?.target || {};
      const isBottomMultimedia = className.includes('fullscreen') || className.includes('muted');

      showDesktopMediaElements({ multimedia, mouseHoverConfig, videoContainer, videoId, isBottomMultimedia });
    }
  },
  initMouseHoverConfig.throttleMove,
  { trailing: false },
);

const handleMouseEnter = ({ mouseHoverConfig, multimedia, videoContainer, videoId }) => {
  showDesktopMediaElements({ multimedia, mouseHoverConfig, videoContainer, videoId });
  mouseHoverConfig.isHover = true;
};

const handleMouseOut = ({ mouseHoverConfig, multimedia, videoContainer, videoId }) => {
  clearTimeout(mouseHoverConfig.disappearDelayId);
  hideDesktopMediaElements({ multimedia, videoContainer, videoId });
  mouseHoverConfig.isHover = false;
};

const createHandlerEvent = (handler, propsEvent) => event => handler(propsEvent, event);

const shoutOnHover = (eventHandlers, { mouseHoverConfig, multimedia, videoContainer, videoId }) => {
  const currentVideoClip = getClipWrapper(videoContainer.current, videoId);
  const propsEvent = { mouseHoverConfig, multimedia, videoContainer, videoId };

  handleMouseEnter({ mouseHoverConfig, multimedia, videoContainer, videoId });

  eventHandlers.mousemove = createHandlerEvent(handleMouseMove, propsEvent);
  eventHandlers.mouseover = createHandlerEvent(handleMouseEnter, propsEvent);
  eventHandlers.mouseleave = createHandlerEvent(handleMouseOut, propsEvent);

  currentVideoClip?.addEventListener('mousemove', eventHandlers.mousemove);
  currentVideoClip?.addEventListener('mouseover', eventHandlers.mouseover);
  currentVideoClip?.addEventListener('mouseleave', eventHandlers.mouseleave);
};

const shoutOffHover = (eventHandlers, { mouseHoverConfig, mediaContainer, videoId }) => {
  const currentVideoClip = getClipWrapper(mediaContainer, videoId);

  if (Object.keys(eventHandlers).length) {
    currentVideoClip?.removeEventListener('mousemove', eventHandlers.mousemove);
    currentVideoClip?.removeEventListener('mouseover', eventHandlers.mouseover);
    currentVideoClip?.removeEventListener('mouseleave', eventHandlers.mouseleave);
    // eslint-disable-next-line no-param-reassign
    eventHandlers = {};
    mouseHoverConfig.isHover = false;
  }
};

const updateMobileMediaElements = ({ multimedia, videoContainer, videoId }) => {
  const [multimediaTypesToShow, multimediaTypesToHide] = mobileEvaluateMediaDisplay(multimedia);

  toggleClipElement(videoContainer.current, videoId, multimediaTypesToHide, false);
  toggleClipElement(videoContainer.current, videoId, multimediaTypesToShow, true);
  toggleClipElement(videoContainer.opposite, videoId, 'thumbnail', true);
};

module.exports = {
  getDesktopMultimediaTypes,
  hideDesktopMediaElements,
  initMouseHoverConfig,
  mobileEvaluateMediaDisplay,
  shoutOffHover,
  shoutOnHover,
  showDesktopMediaElements,
  updateMobileMediaElements,
  handleMouseEnter,
  handleMouseOut,
  handleMouseMove,
};
